/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable no-prototype-builtins */
import React, { Fragment } from 'react'
import { ToastProvider } from 'react-toast-notifications'

import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'
import Footer from './Footer'

import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'

const Layout = ({ children, meta }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          directus {
            globalSettings {
              siteURL
              SEO {
                title
                description
                image {
                  id
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { title, image } = meta || data.directus.globalSettings.SEO || {}
        return (
          <>
            <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
              <script>{`!function(e,t,n,s,i,c){i=t.createElement(n),c=t.getElementsByTagName(n)[0],i.async=1,
	i.src=s,c.parentNode.insertBefore(i,c)}(window,document,"script","https://www.picktime.com/assets/booking.js");
	`}</script>
              {title}
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                rel="stylesheet"
              />
              {/* Add font link tags here */}
            </Helmet>
            <Meta
              url={data.directus.globalSettings.siteURL}
              // googleTrackingId={googleTrackingId}
              absoluteImageUrl={`https://wltdoc.clockworkbee.co.uk/assets/${image.id}`}
              {...data.directus.globalSettings}
              {...meta}
            />
            <ToastProvider>
              <Nav />
              {children}
              <Footer />
            </ToastProvider>
          </>
        )
      }}
    />
  )
}

export default Layout
